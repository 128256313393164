<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="white"
    opacity="0.50"
  >
    <b-card-actions
      ref="lapsedContractsListActionCard"
      :title="'Lapsed Contracts list (' + managersList.length + ')'"
      action-refresh
      action-collapse
      @refresh="loadContracts"
    >
      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <download-excel
            v-if="$can('manage', 'all')"
            :data="managersList"
            :fields="json_fields"
            worksheet="Lapsed Sheet"
            name="lapsed_contracts.xls"
            style="background: #bad000;
          cursor: pointer;
          box-shadow: 2px 4px #e6e6e6;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px; text-align: center"
          >
            Export Excel
          </download-excel>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="managersList"
        responsive
        :fields="$can('manage', 'all') ? adminFields : fields"
        primary-key="id"
        show-empty
        striped
        hover
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(lead.name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.lead.id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.lead.name }}
            </b-link>
            <small class="text-muted">@ {{ data.item.date }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(premium_amount)="data">
          {{ Number(data.item.premium_amount.toFixed()).toLocaleString() }}
        </template>

        <template #cell(agent.name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.agent.id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.agent.name }}
            </b-link>
            <small class="text-muted">{{ data.item.agent.mobile }}</small>
          </b-media>
        </template>

        <template #cell(broker.name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.broker.id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.broker.name }}
            </b-link>
            <small class="text-muted">{{ data.item.broker.mobile }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link
            class="align-middle ml-50 text-success"
            :to="{ name: 'edit_contract', params: { contract_id: data.item.id } }"
          >
            <feather-icon
              size="20"
              icon="EditIcon"
            /></b-link>
        </template>

        <template #cell(full_name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'edit_contract', params: { contract_id: data.item.id } }"
              class="font-weight-bold d-block"
              style="text-decoration: underline; color: #839300;"
            >
              {{ data.item.full_name }}
            </b-link>
            <small class="text-muted">Edit Contract</small>
          </b-media>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="managersList.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card-actions>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {
  BTable, BCol, BRow, BPagination, BLink, BMedia,
  BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'Lapsed',
  components: {
    BTable,
    BCol,
    BRow,
    BLink,
    BMedia,
    BPagination,
    BCardActions,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BOverlay,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50, 75, 100, 'All'],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      avatarText,
      filter: null,
      filterOn: [],
      json_fields: {
        'FULL NAME': 'full_name',
        Mobile: 'lead.mobile',
        Agent: 'agent.name',
        Broker: 'broker.name',
        Lives: 'lives',
        Type: 'type',
        Product: 'product',
        Premium: 'premium_amount',
        Currency: 'currency',
      },
      fields: [
        { key: 'full_name', label: 'FULL NAME', sortable: true },
        { key: 'lead.name', label: 'LEAD', sortable: true },
        { key: 'agent.name', label: 'AGENT', sortable: true },
        { key: 'broker.name', label: 'BROKER', sortable: true },
        { key: 'lives', label: 'LIVES', sortable: true },
        { key: 'type', label: 'TYPE', sortable: true },
        { key: 'product', label: 'PRODUCT', sortable: true },
        { key: 'premium_amount', label: 'ANNUAL PREMIUM', sortable: true },
        { key: 'currency', label: 'CURRENCY', sortable: true },
        { key: 'actions' },
      ],
      adminFields: [
        { key: 'full_name', label: 'FULL NAME', sortable: true },
        { key: 'lead.name', label: 'LEAD', sortable: true },
        { key: 'agent.name', label: 'AGENT', sortable: true },
        { key: 'broker.name', label: 'BROKER', sortable: true },
        { key: 'lives', label: 'LIVES', sortable: true },
        { key: 'type', label: 'TYPE', sortable: true },
        { key: 'product', label: 'PRODUCT', sortable: true },
        { key: 'premium_amount', label: 'ANNUAL PREMIUM', sortable: true },
        { key: 'currency', label: 'CURRENCY', sortable: true },
        { key: 'actions' },
      ],
      showLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      managersList: 'contracts/lapsedContractsList',
    }),
  },
  async created() {
    this.showLoading = true
    await this.loadContracts()
    this.showLoading = false
  },
  methods: {
    ...mapActions({
      loadAllContracts: 'get',
    }),
    async loadContracts() {
      await this.loadAllContracts({
        storeName: 'contracts',
        url: '/admin/contracts/2',
        commitUrl: 'contracts/SET_LAPSED_CONTRACTS',
      })
      this.$refs.lapsedContractsListActionCard.showLoading = false
    },
  },
}
</script>

<style scoped>

</style>
